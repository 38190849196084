<div class="my-4">
  <!-- Button close menu. -->
  <div class="flex justify-between truncate">
    @if (!failedGetOrganization && !isLoading) {
      <div class="flex items-end" id="info-organization">
        <app-photo-file
          [photoVaultId]="organizationInfo.photoVaultId"
          [editPhoto]="false"
          [icon]="'fa-light fa-globe'"
          [iconSizeClass]="'text-xl'"
          class="w-[48px] h-[48px] bg-white rounded-lg"
        />
        <div class="pl-2">
          @if (organizationInfo) {
            <p
              class="font-semibold truncate md:w-[235px] w-[175px] text-base text-white leading-6"
            >
              {{ organizationInfo.name }}
            </p>
          }
        </div>
      </div>
    }
    <!-- Header information of the organization. -->
    @if (isLoading) {
      <div class="items-center py-2">
        <app-loading-indicator
          id="organization-name-loading"
          [inlineText]="'Loading...'"
          [color]="'text-white'"
          [whiteSpinner]="true"
        />
      </div>
    }
    <!-- In case of error in getting the organization. -->
    @if (failedGetOrganization) {
      <p
        class="text-error-500 truncate self-center"
        id="failed-info-organization"
        title="Name of the organization not obtained"
      >
        Name of the organization not obtained
      </p>
    }
    <button
      data-testid="close-menu-dashboard"
      id="close-menu-dashboard"
      mat-icon-button
      type="button"
      (click)="toggleMenu()"
      class="absolute close-menu-dashboard"
    >
      <i class="fa-light fa-lg fa-times text-lg text-white"></i>
    </button>
  </div>
</div>
