@if (notifications.length > 0) {
  <div class="toasts">
    @for (notification of notifications; track $index; let i = $index) {
      <div class="toast">
        <app-notification-card
          (dismissEvent)="removeNotification(i)"
          [notification]="notification"
        />
      </div>
    }
  </div>
}
