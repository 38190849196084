import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { BoardService } from 'src/app/board/board.service';
import { SidenavDrawerService } from 'src/app/core/sidenav-drawer.service';
import { UserService } from 'src/app/core/user.service';
import { TermsGeneric } from 'src/helpers';
import { RightClickMenuDirective } from 'src/helpers/directives/right-click-menu/right-click-menu.directive';
import { NavLink } from 'src/models';

/**
 * Component to show tabs in list of menu drawer of project.
 */
@Component({
  selector: 'app-tabs-navigation-menu',
  standalone: true,
  imports: [CommonModule, RouterModule, RightClickMenuDirective],
  templateUrl: './tabs-navigation-menu.component.html',
  styleUrl: './tabs-navigation-menu.component.scss',
})
export class TabsNavigationMenuComponent implements OnInit, OnDestroy {
  /** Observable for when the component is destroyed. */
  private destroyed$ = new Subject<void>();

  /** User service injection. */
  userService = inject(UserService);

  /** SidenavDrawerService injection. */
  sidenavDrawerService = inject(SidenavDrawerService);

  /** Links available for architect. */
  architectLinks: NavLink[] = [
    {
      name: 'Map',
      link: 'map',
      icon: 'fa-map',
    },
    {
      name: 'Admin',
      link: 'admin',
      icon: 'fa-gear',
    },
    {
      name: 'Search',
      link: 'search',
      icon: 'fa-magnifying-glass',
    },
  ];

  /** List of navigation items. */
  navItems: NavLink[] = [
    {
      name: 'Home',
      link: TermsGeneric.Board.Lower.Plural,
      icon: 'fa-house-blank',
    },
  ];

  /** Whether the signed in user is an isArchitect or not. */
  isArchitect = false;

  /** Service of boardService's. */
  private boardService: BoardService = inject(BoardService);

  /** The Injected Route. */
  private router: Router = inject(Router);

  /** Subscribe to currentActiveBoardId to get rithmId. */
  private subscribeCurrentActiveBoardId$(): void {
    this.boardService.currentActiveBoardId$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((activeBoardId) => {
        const currentUrl = this.router.url;
        const boards = TermsGeneric.Board.Lower.Plural;
        this.navItems.forEach((item) => {
          if (item.link === boards) {
            item.isActive =
              currentUrl === `/${boards}` ||
              activeBoardId === this.userService.user().defaultDashboardId;
          } else {
            item.isActive = currentUrl.startsWith(`/${item.link}`);
          }
        });
      });
  }

  /**
   * Life cycle.
   */
  ngOnInit(): void {
    this.isArchitect = this.userService.isAdmin();
    if (this.isArchitect) {
      this.navItems.splice(1, 0, ...this.architectLinks);
    }
    this.subscribeCurrentActiveBoardId$();
  }

  /** Clean subscriptions. */
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
