import { inject, Injectable } from '@angular/core';
import { Subject, first, takeUntil, throwError } from 'rxjs';
import {
  Alert,
  ActionsStatusRunner,
  TokenResponse,
  WorkerSignalRCommunication,
  EventOfContainerSignalR,
  EventOfBoardAndFolderSignalR,
} from 'src/models';
import * as signalR from '@microsoft/signalr';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';
import { MapAlertService } from 'src/app/new-map/core';
import { MapActiveUser } from 'src/app/new-map/models';
import { PowerService } from './power.service';
import { ActionRunnerHelper } from 'src/helpers/action-runner-helper';
import { NetworkService } from './network.service';
import { WorkerObjectCommunication } from 'src/models/worker-object-communication';
import {
  ALERT_HUB_PATH,
  AUTO_RECONNECTIONS,
  ATTEMPTS_LIMIT_CONNECTION,
} from 'src/helpers/workers/signal-r-const';
import { ContainerService } from './container.service';
import { BoardService } from '../board/board.service';

/** SignalR service. */
@Injectable({
  providedIn: 'root',
})
export class SignalRService {
  /** Subject of type Alert received from SignalR hub.  */
  receivedAlert$: Subject<Alert> = new Subject<Alert>();

  /** SignalR hub connection object. */
  connection: signalR.HubConnection | undefined;

  /** The attempts to start connection. */
  attemptsToStartConnection = 0;

  /** Network status. */
  networkStatus = true;

  /** Web worker of signalR. */
  workerSignalR!: Worker;

  /** If can use web worker. */
  workerMode = false;

  /** Action runner helper. */
  actionRunnerHelper = ActionRunnerHelper;

  /** Observable for when the component is destroyed. */
  private destroyed$ = new Subject<void>();

  /** Container service. */
  private containerService = inject(ContainerService);

  /** Board service. */
  private boardService = inject(BoardService);

  constructor(
    private userService: UserService,
    private mapAlertService: MapAlertService,
    private powerService: PowerService,
    private networkService: NetworkService,
  ) {
    this.workerMode = typeof Worker !== 'undefined';
  }

  /** Subscribe to know network status. */
  subscribeNetworkStatus$(): void {
    this.networkService.networkStatus$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((status) => {
        this.networkStatus = status;
        if (this.workerMode) {
          this.sendMessageWorker({
            event: WorkerSignalRCommunication.UpdateNetWorkStatus,
            params: {
              status,
            },
          });
        } else if (status) {
          this.startSignalR();
        }
      });
  }

  /**
   * Connect signalR from worker or on this context.
   */
  connect(): void {
    this.subscribeNetworkStatus$();
    this.workerMode ? this.connectWorkerSignalR() : this.connectToSignalR();
  }

  /**
   * Connect signalR by web worker.
   */
  connectWorkerSignalR(): void {
    this.workerSignalR = new Worker(
      new URL('../../helpers/workers/signal-r.worker', import.meta.url),
      {
        type: 'module',
      },
    );

    this.workerSignalR.onmessage = ({
      data,
    }: MessageEvent<WorkerObjectCommunication>) => {
      this.workerProcessEvents(data);
    };

    this.sendMessageWorker({
      event: WorkerSignalRCommunication.Connect,
      params: {
        token: this.userService.accessToken?.token,
        baseApiUrl: environment.baseApiUrl,
      },
    });
  }

  /**
   * Connect with SignalR hub if not connected.
   *
   */
  connectToSignalR(): void {
    if (!this.userService.accessToken) {
      throwError(() => {
        throw new Error('User token not found!!');
      });
    } else {
      this.connection = new signalR.HubConnectionBuilder()
        .withUrl(`${environment.baseApiUrl}${ALERT_HUB_PATH}`, {
          accessTokenFactory: () => this.userService.accessToken?.token || '',
        })
        .withAutomaticReconnect(AUTO_RECONNECTIONS)
        .build();

      this.startSignalR();

      // When the connection tries to be reconnected.
      this.connection.onreconnecting(() => {
        // Clear containers pending when connection is closed.
        this.clearContainersProcessing();
        this.refreshToken();
      });

      // When connection is closed try to refresh token and stablish connection again.
      this.connection.onclose(() => {
        // We reset the counter, if successful.
        this.attemptsToStartConnection = 0;
        // Clear containers pending when connection is closed.
        this.clearContainersProcessing();
        // Valid if have internet, and if exist token valid.
        if (this.networkStatus && this.userService.accessToken?.token) {
          this.refreshToken();
        }
      });

      // Methods invoked.
      this.connection.on('AlertMessage', (newAlert: Alert) => {
        this.alertMessageNotifications(newAlert);
      });

      this.connection.on('UserServiceAdd', (userAdd: MapActiveUser) => {
        this.activeUsersMap(userAdd);
      });

      this.connection.on('UserServiceRemove', (userRemove: MapActiveUser) => {
        this.removeActiveUsersMap(userRemove);
      });

      this.connection.on(
        'ActionStatusMessage',
        (actions: ActionsStatusRunner[]) => {
          this.actionStatusMessage(actions);
        },
      );

      this.connection.on(
        'SendNotificationFromBoardAndFolders',
        (eventData: EventOfBoardAndFolderSignalR) => {
          this.eventOfBoardAndFolderSignalR(eventData);
        },
      );

      this.connection.on(
        'SendNotificationFromContainer',
        (containerEventData: EventOfContainerSignalR) => {
          this.containerEventData(containerEventData);
        },
      );
    }
  }

  /**
   * Send message to worker.
   * @param data Data to send.
   */
  sendMessageWorker(data: WorkerObjectCommunication): void {
    this.workerSignalR.postMessage(data);
  }

  /**
   * Worker process listen.
   * @param data Data web worker.
   */
  workerProcessEvents(data: WorkerObjectCommunication): void {
    const { event, params } = data;
    switch (event) {
      case WorkerSignalRCommunication.AlertMessage:
        this.alertMessageNotifications(params as Alert);
        break;
      case WorkerSignalRCommunication.UserServiceAdd:
        this.activeUsersMap(params as MapActiveUser);
        break;
      case WorkerSignalRCommunication.UserServiceRemove:
        this.removeActiveUsersMap(params as MapActiveUser);
        break;
      case WorkerSignalRCommunication.ActionStatusMessage:
        this.actionStatusMessage(params as ActionsStatusRunner[]);
        break;
      case WorkerSignalRCommunication.RefreshToken:
        this.updateToken();
        break;
      case WorkerSignalRCommunication.ClearContainers:
        // Clear containers pending when connection is closed.
        this.clearContainersProcessing();
        break;
      case WorkerSignalRCommunication.BoardAndFolder:
        this.eventOfBoardAndFolderSignalR(
          params as unknown as EventOfBoardAndFolderSignalR,
        );
        break;

      case WorkerSignalRCommunication.ContainerEvent:
        this.containerEventData(params as unknown as EventOfContainerSignalR);
        break;
    }
  }

  /**
   * Method to clear containers processing and evite event to refresh in all components necessary.
   */
  private clearContainersProcessing() {
    // Empty container processing to ensure not keep processing all time.
    this.actionRunnerHelper.clearContainersProcessing();
    // Send event to refresh in all components and evite keep processing in app.
    this.actionStatusMessage([]);
  }

  /** Refresh token to try to connect to the signal correctly. */
  refreshToken(): void {
    // Not connected and still having connection attempts.
    if (
      this.connection &&
      this.connection.state !== signalR.HubConnectionState.Connected &&
      this.attemptsToStartConnection <= ATTEMPTS_LIMIT_CONNECTION
    ) {
      this.updateToken();
    }
  }

  /**
   * Update token.
   */
  updateToken(): void {
    this.userService
      .refreshToken()
      .pipe(first())
      .subscribe({
        next: (tokenResponse: TokenResponse) => {
          if (this.workerMode) {
            this.sendMessageWorker({
              event: WorkerSignalRCommunication.RefreshToken,
              params: {
                token: tokenResponse.accessToken,
              },
            });
          } else {
            this.attemptsToStartConnection++;
            this.startSignalR();
          }
        },
      });
  }

  /** Start the signalR connection. */
  startSignalR(): void {
    if (
      this.connection &&
      this.connection.state === signalR.HubConnectionState.Disconnected
    ) {
      this.connection
        .start()
        .then(() => {
          // We reset the counter, if successful.
          this.attemptsToStartConnection = 0;
        })
        .catch((error: unknown) => {
          if (
            String(error).includes('Invalid Token') ||
            String(error).includes('Failed to fetch')
          ) {
            // Refresh the token again.
            this.refreshToken();
          }
        });
    }
  }

  /**
   * Alert message notification event.
   * @param newAlert Alert to emit.
   */
  alertMessageNotifications(newAlert: Alert): void {
    this.receivedAlert$.next(newAlert);
  }

  /**
   * User service add  notification event.
   * @param userAdd User to add.
   */
  activeUsersMap(userAdd: MapActiveUser): void {
    this.mapAlertService.activeUsers$.next(userAdd);
  }

  /**
   * User remove notification event.
   * @param userRemove User to remove.
   */
  removeActiveUsersMap(userRemove: MapActiveUser): void {
    this.mapAlertService.deleteActiveUser$.next(userRemove.rithmId);
  }

  /**
   * Actions container status.
   * @param actions Actions to process.
   */
  actionStatusMessage(actions: ActionsStatusRunner[]): void {
    this.powerService.updateActionsStatus(actions);
  }

  /**
   * Event boards and folder.
   * @param event Event data.
   */
  eventOfBoardAndFolderSignalR(event: EventOfBoardAndFolderSignalR): void {
    this.boardService.getEventsOfBoardAndFolderBySignalR(event);
  }

  /**
   * Container event data.
   * @param containerData Actions to process.
   */
  containerEventData(containerData: EventOfContainerSignalR): void {
    this.containerService.getEventsOfContainerBySignalR(containerData);
  }

  /** Disconnect current signalR connection. */
  disconnectSignalR(): void {
    if (
      this.connection &&
      this.connection.state !== signalR.HubConnectionState.Disconnected
    ) {
      this.connection.stop();
    }
  }

  /**
   * On destroy life cycle.
   */
  onDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    if (this.workerMode) {
      this.sendMessageWorker({
        event: WorkerSignalRCommunication.Disconnect,
      });
      this.workerSignalR.terminate();
    } else {
      this.disconnectSignalR();
    }
  }
}
