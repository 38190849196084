import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, shareReplay } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Alert } from 'src/models';

const MICROSERVICE_PATH = '/alertservice/api/alert';

/**
 * Service for all behavior involving alerts.
 */
@Injectable({
  providedIn: 'root',
})
export class AlertService {
  /** Subject to pass the Alert and show in the notification-list panel.  */
  passingAlert$ = new Subject<Alert[]>();

  constructor(private http: HttpClient) {}

  /**
   * Gets a list of alerts.
   * @param includeRead To include read alerts also or not.
   * @returns A list of alerts.
   */
  getAlerts(includeRead = false): Observable<Alert[]> {
    const params = new HttpParams().set('includeRead', includeRead);
    return this.http
      .get<Alert[]>(`${environment.baseApiUrl}${MICROSERVICE_PATH}/alerts`, {
        params,
      })
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  /**
   * Posts a list of rithm Ids to mark as read.
   * @param alertIds Rithm Ids of alerts.
   * @returns Observable of alert rithm Ids.
   */
  markAlertsAsRead(alertIds: string[]): Observable<string[]> {
    return this.http
      .post<
        string[]
      >(`${environment.baseApiUrl}${MICROSERVICE_PATH}/markread`, alertIds)
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  /**
   * Save or update the alert notifications.
   * @param alertRithmId The value that will be update.
   * @param noteData The note data value that will be update.
   * @returns The list of updated alerts.
   */
  updateAlerts(alertRithmId: string, noteData: string): Observable<string> {
    const standardBody = { note: noteData };
    return this.http
      .put<string>(
        `${environment.baseApiUrl}${MICROSERVICE_PATH}alertRithmId=${alertRithmId}`,
        standardBody,
      )
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  /**
   * It delete the alert of specific notification.
   * @param alertRithmId This Id notification will be removed.
   * @returns Returns an empty observable.
   */
  deleteAlert(alertRithmId: string): Observable<unknown> {
    const params = new HttpParams().set('alertRithmId', alertRithmId);
    return this.http
      .post<unknown>(
        `${environment.baseApiUrl}${MICROSERVICE_PATH}/deleteAlert`,
        {},
        { params },
      )
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }
}
