import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { TokenInterceptor } from './token.interceptor';
import { CookieService } from 'ngx-cookie-service';
import { HttpCacheInterceptorModule } from '@ngneat/cashew';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpCacheInterceptorModule.forRoot(),
    MatDialogModule,
    MatSnackBarModule,
    DialogComponent,
  ],
  providers: [
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class CoreModule {}
