<div class="w-full h-full">
  <!-- Header each section -->
  <div class="flex h-[50px] justify-between items-center p-1">
    <div class="w-full flex justify-between items-center">
      <span class="text-gray-400 text-sm not-italic font-medium">
        {{
          dashboardRole === enumRoleDashboardMenu.Company
            ? 'Org '
            : dashboardRole === enumRoleDashboardMenu.Personal
              ? 'My ' + termsGeneric.Board.Title.Plural
              : 'Bookmarked'
        }}
      </span>
      @if (isErrorUpdating) {
        <span class="w-[60%] text-error-500 text-xs text-center animate-pulse">
          <i class="fa-light fa-triangle-exclamation"></i> An error occurred
          while updating</span
        >
      }
    </div>
    @if (
      (dashboardRole === enumRoleDashboardMenu.Company ||
        dashboardRole === enumRoleDashboardMenu.Personal) &&
      !editMode &&
      isArchitect
    ) {
      <app-options-menu-drawer
        id="options"
        class="hidden sm:block"
        [dashboardRole]="dashboardRole"
        [isDashboardListOptions]="true"
        [isArchitect]="isArchitect"
        [flagManagementMembersUpdate]="flagManagementMembersUpdate"
        (eventSetDefaultDashboard)="setDefaultDashboard(false, $event)"
        (eventAddNewFolderDashboard)="addNewFolderDashboard()"
      />
    }
  </div>

  <!--Folder Section-->

  @if (
    dashboardRole === enumRoleDashboardMenu.Personal ||
    dashboardRole === 'bookmarked'
  ) {
    @if (isErrorGettingFolders || isLoadingFolders) {
      <ng-container
        [ngTemplateOutlet]="loadingAndError"
        [ngTemplateOutletContext]="{ msg: 'Folders' }"
      />
    }
    <!--Mat tree-->
    <div id="section-folder">
      @if (
        (dashboardRole === enumRoleDashboardMenu.Personal ||
          dashboardRole === 'bookmarked') &&
        !isErrorGettingFolders &&
        !isLoadingFolders
      ) {
        <ng-container [ngTemplateOutlet]="tree" />
      }
    </div>
  }
  <!-- List dashboards. -->
  @if (!isLoading && !showError) {
    <ng-container
      [ngTemplateOutlet]="dashboardList"
      [ngTemplateOutletContext]="{
        dashboards: dashboardsList,
        folder: null,
        id: 'dashboard-list-' + dashboardRole
      }"
    />
  } @else {
    <ng-container [ngTemplateOutlet]="loadingAndError" />
  }
  @if (
    (dashboardRole === enumRoleDashboardMenu.Personal
      ? !dashboardsList.length && !folderDashboardData.length
      : !dashboardsList.length) &&
    !isLoading &&
    !showError &&
    dashboardRole !== 'bookmarked'
  ) {
    <div
      id="empty-dashboards-list"
      class="flex justify-center items-center text-base pb-5 pt-3"
    >
      <p class="truncate text-white">
        There are no dashboards
        {{ !search ? 'to list' : ' matching "' + search + '"' }}.
      </p>
    </div>
  }
</div>

<ng-template #tree>
  <div
    class="list-boards"
    id="folder-list"
    cdkDropList
    [cdkDropListDisabled]="!editMode"
    [cdkDropListData]="folderDashboardData"
    (cdkDropListDropped)="dropFolder($event)"
  >
    @for (folder of folderDashboardData; track folder.rithmId) {
      <div cdkDrag>
        <div
          class="w-full flex justify-between items-center option-list option-background dark:bg-secondary-900 dark:text-secondary-100 mt-2"
          (click)="expanded[folder.rithmId] = !expanded[folder.rithmId]"
        >
          <div class="w-[85%] flex items-center">
            <button
              mat-icon-button
              type="button"
              [id]="'expanded-button-' + folder.rithmId"
              [attr.data-testid]="'expanded-button-' + folder.rithmId"
            >
              <i
                class="fa-solid text-gray-500 text-sm"
                [ngClass]="{
                  'fa-caret-down': expanded[folder.rithmId],
                  'fa-caret-right': !expanded[folder.rithmId],
                  'text-gray-300': !folder.dashboards.length
                }"
              >
              </i>
            </button>

            <div class="flex justify-center w-8">
              @if (!folder.imageId) {
                <i class="fa-light fa-folder text-xl text-gray-400"></i>
              } @else {
                <app-avatar-file
                  [name]="folder.name"
                  [photoVaultId]="folder.imageId"
                />
              }
            </div>
            <span
              class="text-base ml-2 w-full truncate dark:text-secondary-100 font-medium text-white"
            >
              {{ folder.name }}
            </span>
          </div>
          @if (!editMode) {
            <app-options-menu-drawer
              class="showOptions"
              [dashboardRole]="dashboardRole"
              [isDashboardListOptions]="false"
              [isPrincipalPageDashboard]="isPrincipalPageDashboard"
              [isDefaultDashboard]="
                userService.user().defaultDashboardId === folder.rithmId
              "
              [folderData]="folder"
              [isFolder]="true"
              [isArchitect]="isArchitect"
              [flagManagementMembersUpdate]="flagManagementMembersUpdate"
              (eventSetDefaultDashboard)="setDefaultDashboard(false, $event)"
              (eventConfirmDelete)="
                confirmActionDelete($event.isFolder, $event.element)
              "
            />
          } @else {
            <i
              cdkDragHandle
              class="fa-light fa-grip-dots-vertical p-2 text-gray-400 drag-icon"
            ></i>
          }
        </div>
        <div>
          <ng-container
            [ngTemplateOutlet]="dashboardList"
            [ngTemplateOutletContext]="{
              dashboards: folder.dashboards,
              folder,
              id: folder.rithmId + '-' + dashboardRole
            }"
          />
        </div>
      </div>
    }
  </div>
</ng-template>

<ng-template
  #dashboardList
  let-dashboards="dashboards"
  let-folder="folder"
  let-id="id"
>
  <div
    class="list-boards"
    [id]="id"
    cdkDropList
    [cdkDropListData]="dashboards"
    [cdkDropListDisabled]="!editMode"
    (cdkDropListDropped)="dropDashboard($event)"
    [cdkDropListConnectedTo]="connectTo(folder ? folder.rithmId : '')"
  >
    <div
      [ngClass]="{
        block: !folder || (folder && expanded[folder.rithmId]),
        hidden: folder && !expanded[folder.rithmId],
        'pl-8': folder,
        'min-h-[35px]': folder && expanded[folder.rithmId]
      }"
    >
      @if (folder && expanded[folder.rithmId] && !folder.dashboards.length) {
        <div class="text-secondary-200 flex justify-center m-1">
          <span> No items to display </span>
        </div>
      }
      @for (dashboard of dashboards; track dashboard.rithmId; let i = $index) {
        <div
          cdkDrag
          class="flex justify-between items-center option-list select-none dark:bg-secondary-900 dark:text-secondary-100 mt-2"
          [ngClass]="{
            'current-option': currentBoardId === dashboard.rithmId,
            'option-background': !(currentBoardId === dashboard.rithmId),
            'cursor-pointer': !editMode
          }"
          (click)="goToDashboard(dashboard.rithmId)"
          [attr.data-testid]="dashboardRole + '-item-' + i"
          [attr.id]="dashboardRole + '-item-' + i"
          appRightClickMenu
          [link]="
            '/' + termsGeneric.Board.Lower.Plural + '/' + dashboard.rithmId
          "
          [enableRightClick]="!isArchitect"
        >
          <div class="w-11/12 truncate">
            <span class="text-base font-medium leading-6 text-white">{{
              dashboard.name.trim() ||
                'Untitled ' + termsGeneric.Board.Title.Single + '.'
            }}</span>
          </div>

          <div>
            @if (!editMode) {
              <div>
                <ng-container
                  [ngTemplateOutlet]="iconDefaultDashboard"
                  [ngTemplateOutletContext]="{ $implicit: dashboard.rithmId }"
                />
              </div>
              <app-options-menu-drawer
                class="showOptions"
                [dashboardRole]="dashboardRole"
                [isDashboardListOptions]="false"
                [dashboardData]="dashboard"
                [isPrincipalPageDashboard]="isPrincipalPageDashboard"
                [isDefaultDashboard]="
                  userService.user().defaultDashboardId === dashboard.rithmId
                "
                [isArchitect]="isArchitect"
                [flagManagementMembersUpdate]="flagManagementMembersUpdate"
                (eventSetDefaultDashboard)="setDefaultDashboard(false, $event)"
                (eventAddNewFolderDashboard)="addNewFolderDashboard()"
                (eventConfirmDelete)="
                  confirmActionDelete($event.isFolder, $event.element)
                "
              />
            } @else {
              <i
                cdkDragHandle
                class="fa-light fa-grip-dots-vertical p-2 text-gray-400 drag-icon"
              ></i>
            }
          </div>
        </div>
      }
    </div>
  </div>
</ng-template>

<!-- Show error or load indicator. -->
<ng-template #loadingAndError let-msg="msg">
  <div class="w-full h-full flex justify-center items-center py-5 text-white">
    @if (isLoading || isLoadingFolders) {
      <div id="loading-dashboards-list">
        <app-loading-indicator
          [inlineText]="
            'Loading ' + (msg || termsGeneric.Board.Title.Plural) + '...'
          "
          color="'text-white'"
          [whiteSpinner]="true"
        />
      </div>
    }
    @if (showError || isErrorGettingFolders) {
      <div id="error-dashboard-list">
        <p class="text-error-500 text-center">
          Error: Failed to obtain {{ msg || termsGeneric.Board.Title.Plural }}
        </p>
      </div>
    }
  </div>
</ng-template>

<!-- Show default icon for dashboard. -->
<ng-template #iconDefaultDashboard let-dashboardRithmId>
  @if (userService.user().defaultDashboardId === dashboardRithmId) {
    <i
      class="fa-light fa-house-blank text-white text-base default-dashboard p-2"
    ></i>
  }
</ng-template>
