@if (showMessageActions) {
  <div id="app" (mouseenter)="pauseClick()" (mouseleave)="restartClick()">
    @for (notification of alertList.slice(0, 5).reverse(); track $index) {
      <div [id]="'alert-' + notification.rithmId" class="box transition-popup">
        <header class="box__header">
          <button
            mat-icon-button
            type="button"
            id="close-modal-data-bucket"
            data-testid="close-modal-data-bucket"
            class="ButtonClose"
            (click)="closeSnackItem(notification.rithmId)"
          >
            <i class="fas fa-times text-sm text-secondary-500"></i>
          </button>
        </header>
        <app-notification-card
          [notification]="notification"
          [fromMenu]="true"
          [justAlert]="true"
        />
      </div>
    }
  </div>
}
