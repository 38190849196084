import { CommonModule, NgOptimizedImage } from '@angular/common';
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  first,
  Subject,
  takeUntil,
  interval,
  Observable,
  PartialObserver,
} from 'rxjs';
import { AlertService } from 'src/app/core/alert.service';
import { PopupService } from 'src/app/core/popup.service';
import { SignalRService } from 'src/app/core/signalR-service';
import { Alert, AlertType } from 'src/models';
import { NotificationCardComponent } from '../notification-card/notification-card.component';

/**
 * Component will handle the Push Notification Stack.
 */
@Component({
  selector: 'app-notification-push-menu-container',
  templateUrl: './notification-push-menu-container.component.html',
  styleUrls: ['./notification-push-menu-container.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    NotificationCardComponent,
    NgOptimizedImage,
  ],
})
export class NotificationPushMenuContainerComponent
  implements OnInit, OnDestroy
{
  /** Enable the notification stack. */
  public showMessageActions = true;

  /** Observable for when the component is destroyed. */
  private destroyed$ = new Subject<void>();

  /** Included read alert. */
  includeRead = true;

  /** Notification alert mark read alertId. */
  alertIds: string[] = [];

  /** List of all the alerts. */
  alertList: Alert[] = [];

  /** It will hold the current values for timer. */
  progressNum = 0;

  //** Timer observable. */
  timer$: Observable<number> | undefined;

  //** Observer which holds the timer. */
  timerObserver: PartialObserver<number> | undefined;

  //** It will subscribe to the stop event. */
  stopClick$ = new Subject<void>();

  //** It will pause the timer when user hovers on the stack */
  pauseClick$ = new Subject<void>();

  //** Notification Type set to Push */
  type = AlertType.Push;

  constructor(
    private alertService: AlertService,
    private popupService: PopupService,
    private signalRService: SignalRService,
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) {}

  /**
   * Initial function call.
   */
  ngOnInit(): void {
    this.showMessageActions = true;
    this.getAlerts();
    this.subscribeNewNotification();
  }

  /** Subscribe to signalR notification event. */
  private subscribeNewNotification(): void {
    this.signalRService.receivedAlert$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((alert) => {
        if (alert.type === AlertType.Push && alert.hasRead === false) {
          this.alertList = [alert].concat(this.alertList);
          this.progressNum = 0;
          this.showMessageActions = true;
          this.initializeTimer();
          setTimeout(() => {
            this.closeSnackItem(alert.rithmId);
          }, 5500);
        }
      });
  }

  /**
   * Initialize the timer.
   */
  initializeTimer(): void {
    this.timer$ = interval(1000).pipe(
      takeUntil(this.pauseClick$),
      takeUntil(this.stopClick$),
    );

    this.timerObserver = {
      next: () => {
        if (this.progressNum < 10) {
          this.progressNum += 1;
        } else {
          this.stopClick$.next();
          this.elapseTime();
          if (this.alertList.length) {
            this.passAlert();
          }
        }
      },
    };
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this.timer$.subscribe(this.timerObserver);
  }

  /**
   * Pause the Timer.
   */
  passAlert(): void {
    this.alertService.passingAlert$.next(this.alertList);
    this.alertList = [];
  }

  /**
   * Pause the Timer.
   */
  pauseClick(): void {
    this.pauseClick$.next();
  }

  /**
   * Restart the the Timer.
   */
  restartClick(): void {
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this.timer$?.subscribe(this.timerObserver);
  }

  /**
   * Close the stack bar.
   */
  elapseTime(): void {
    this.showMessageActions = false;
  }

  /**
   * Gets a list of alerts.
   */
  private getAlerts(): void {
    this.alertService
      .getAlerts(this.includeRead)
      .pipe(first())
      .subscribe({
        next: (data) => {
          this.alertList = this.filterByNotificationType(data);
          this.initializeTimer();
        },
        error: () => {
          this.popupService.notify(
            "Something went wrong on our end and we're looking into it. Please try again in a little while.",
            true,
          );
        },
      });
  }

  /**
   *Filter the alert list by Push Notification.
   * @param alertType The current alert type filter.
   * @returns A list of alert filters as object.
   */
  private filterByNotificationType(alertType: Alert[]): Alert[] {
    const alertList = alertType.filter(
      (item) => item.type === AlertType.Push && item.hasRead === false,
    );
    return alertList;
  }

  /**
   * Update alerts mark read list.
   *
   */
  private markAlertsAsRead(): void {
    this.alertService
      .markAlertsAsRead(this.alertIds)
      .pipe(first())
      .subscribe({
        next: (ids) => {
          if (ids) {
            this.alertIds.map((alertId) => {
              const alertIndex = this.alertList.findIndex(
                (alert) => alert.rithmId === alertId,
              );
              this.alertList.splice(alertIndex, 1);
            });
          }
        },
        error: () => {
          this.popupService.notify(
            "Something went wrong on our end and we're looking into it. Please try again in a little while.",
            true,
          );
        },
      });
  }

  /**
   * Close the push notification card.
   * @param rithmId RithmId of the closing card.
   */
  closeSnackItem(rithmId: string): void {
    const alert = this.elementRef.nativeElement.querySelector(
      '#alert-' + rithmId,
    );
    const padre = alert.parentNode;
    this.renderer.removeChild(padre, alert);
    this.alertIds = [];
    this.alertIds.push(rithmId);
    this.markAlertsAsRead();
  }

  /**
   * Completes all subscriptions.
   */
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
