import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { first, forkJoin } from 'rxjs';
import { BoardService } from 'src/app/board/board.service';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  DashboardOrFolderMemberPermission,
  EntityType,
  MemberBoard,
  RoleDashboardMenu,
  TeamBoard,
  UpdateType,
} from 'src/models';
import { TermsGeneric } from 'src/helpers';
import { UserService } from 'src/app/core/user.service';
import { PopupService } from 'src/app/core/popup.service';
import { FolderService } from 'src/app/board/folder.service';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { TableMembersComponent } from 'src/app/shared/table-members/table-members.component';
import { LoadingIndicatorComponent } from 'src/app/shared/loading-indicator/loading-indicator.component';
import { LoadingWidgetComponent } from 'src/app/shared/widget-dashboard/loading-widget/loading-widget.component';
import { CommonModule } from '@angular/common';
import { MemberBoardListModalComponent } from 'src/app/board/management-member-board-modal/member-board-list-modal/member-board-list-modal.component';
import { ErrorWidgetComponent } from 'src/app/shared/widget-dashboard/error-widget/error-widget.component';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';

/**Interface data modal. */
interface ManagementMemberDataDashboard {
  /** Selected folder or  dashboardRithmId. */
  rithmId: string;
  /** Selected dashboardType. */
  dashboardType: RoleDashboardMenu;
  /** Is folder. */
  isFolder: boolean;
  /** Id of the user who owns the dashboard. */
  ownUserRithmId: string;
  /** Treatment show management member update. */
  flagManagementMembersUpdate: boolean;
  /** Board folder name.*/
  entityName: string;
}

enum TypeFilter {
  /** Teams type. */
  Teams = 'teams',
  /** Members type. */
  Members = 'members',
  /** All type. */
  All = 'All',
  /** Admin type. */
  Admin = 'admin',
  /** Member type. */
  Member = 'member',
}

/** Type tabs. */
type TypeTabs = 'member' | 'team' | 'teamMember' | 'addMembers';

/** Manage members modal. */
@Component({
  selector: 'app-management-member-dashboard-modal',
  standalone: true,
  imports: [
    MemberBoardListModalComponent,
    CommonModule,
    MatDialogModule,
    MatCheckboxModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatMenuModule,
    MatButtonModule,
    ErrorWidgetComponent,
    LoadingWidgetComponent,
    LoadingIndicatorComponent,
    TableMembersComponent,
  ],
  templateUrl: './management-member-board-modal.component.html',
  styleUrls: ['./management-member-board-modal.component.scss'],
})
export class ManagementMemberBoardModalComponent implements OnInit {
  /** Index default in tabs. */
  indexTab = 0;

  /** Entity type. */
  entityType = EntityType;

  /**
   * If its able or disabled save button.
   * @returns A boolean if its disable button.
   */
  get disableSaveButton(): boolean {
    return (
      ![
        ...this.getUsersTeamsToUpdate('member'),
        ...this.getUsersTeamsToUpdate('team'),
      ].length || this.isLoading
    );
  }

  /**
   * Get status canViewAll.
   * @returns Status canViewAll.
   */
  get canViewAll(): boolean {
    return this.form.controls['canViewAll'].value;
  }

  /** Members to dashboard or folder. */
  allMembers: MemberBoard[] = [];

  /** Teams to dashboard or  folder. */
  allTeams: TeamBoard[] = [];

  /**
   * Members and Teams with filters.
   */
  membersFiltered: Record<TypeTabs, (TeamBoard | MemberBoard)[]> = {
    member: [],
    team: [],
    teamMember: [],
    addMembers: [],
  };

  /**
   * Members and Teams with filters.
   */
  membersSpecific: Record<TypeTabs, (TeamBoard | MemberBoard)[]> = {
    member: [],
    team: [],
    teamMember: [],
    addMembers: [],
  };

  /** Selected folder or dashboardRithmId. */
  rithmId!: string;

  /** Selected dashboardType. */
  dashboardType!: RoleDashboardMenu;

  /** Board folder name.*/
  entityName = '';

  /** Id of the user who owns the dashboard. */
  ownUserRithmId = '';

  /** Enum type of role dashboard. */
  enumRoleDashboardMenu = RoleDashboardMenu;

  /** Form users. */
  form!: FormGroup;

  /** Loading get user members. */
  isLoading = false;

  /** Loading when update members. */
  isUpdating: UpdateType | '' = '';

  /** Show error if get users members fails. */
  errorGetUsersMember = false;

  /** Is folder. */
  isFolder = false;

  /** Treatment management members.*/
  flagManagementMembersUpdate = false;

  /** Filter options. */
  displayMembers: TypeFilter[] = [
    TypeFilter.All,
    TypeFilter.Teams,
    TypeFilter.Members,
  ];

  /** Filter options. */
  roles: TypeFilter[] = [TypeFilter.All, TypeFilter.Admin, TypeFilter.Member];

  /** Columns statics to show on table members. */
  displayedColumns = ['check', 'memberName', 'roleType', 'option'];

  /** Selected filter. */
  selectedFilter: TypeFilter = TypeFilter.All;

  /** System-wide generic terms. */
  termsGeneric = TermsGeneric;

  /** Enum types filter. */
  enumTypeFilter = TypeFilter;

  /** Search value. */
  search = '';

  /** Whether or not the add member tab is loading. */
  isLoadingTabAddMember = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public modalData: ManagementMemberDataDashboard,
    private boardService: BoardService,
    private fb: FormBuilder,
    private userService: UserService,
    private popupService: PopupService,
    private folderService: FolderService,
  ) {}

  /** Init method. */
  ngOnInit(): void {
    this.form = this.fb.group({
      canViewAll: this.fb.control(false),
    });

    // Should change for rithmId one name more general because receive ids the folders and dashboards.
    this.rithmId = this.modalData.rithmId;
    this.dashboardType = this.modalData.dashboardType;
    this.isFolder = this.modalData.isFolder;
    this.ownUserRithmId = this.modalData.ownUserRithmId;
    this.flagManagementMembersUpdate =
      this.modalData.flagManagementMembersUpdate;
    // We avoid the request.
    if (!this.flagManagementMembersUpdate) {
      this.getUsersAndTeams();
    }
    this.entityName = this.modalData.entityName;
  }

  /** Add form for each user. */
  private addForms(): void {
    [...this.allMembers, ...this.allTeams].map((member) => {
      this.form.addControl(
        member.rithmId,
        this.fb.control({
          canView: member.canView,
          isEditable: member.isEditable,
        }),
      );
    });
  }

  /**
   * Filter search by member and team.
   * @param member Member | Team.
   * @returns True if exist.
   */
  private getValidateSearch(member: MemberBoard | TeamBoard): boolean {
    if ('name' in member) {
      return member.name.toLowerCase().includes(this.search.toLowerCase());
    }
    return (
      `${member.firstName} ${member.lastName}`
        .toLowerCase()
        .includes(this.search.toLowerCase()) ||
      member.email.toLowerCase().includes(this.search.toLowerCase())
    );
  }

  /**
   * Filter option menu by member and team.
   * @param member Member | Team.
   * @returns True if exist.
   */
  private getValidateFilter(member: MemberBoard | TeamBoard): boolean {
    if (this.indexTab === 3) {
      return this.selectedFilter === this.enumTypeFilter.Members
        ? 'email' in member
        : this.selectedFilter === this.enumTypeFilter.Teams
          ? 'name' in member
          : true;
    } else {
      return this.selectedFilter === this.enumTypeFilter.Member
        ? member.canView && !member.isEditable
        : this.selectedFilter === this.enumTypeFilter.Admin
          ? member.canView && member.isEditable
          : true;
    }
  }

  /**
   * Clear filters.
   */
  clearFilters(): void {
    const isLoadFilters =
      this.selectedFilter !== this.enumTypeFilter.All || !this.search;
    this.selectedFilter = this.enumTypeFilter.All;
    this.search = '';
    isLoadFilters && this.filterMembers();
  }

  /**
   * Callback when click select all.
   */
  onChangeSelectAll(): void {
    this.membersSpecific.addMembers.map((member) => {
      this.form.patchValue({
        [member.rithmId]: {
          canView: this.canViewAll,
          isEditable: !this.canViewAll
            ? false
            : this.form.controls[member.rithmId].value.isEditable,
        },
      });
    });
  }

  /**
   * Deselect canView all.
   */
  deselectCanViewAll(): void {
    this.form.controls['canViewAll'].reset();
  }

  /** Get users to dashboard or folder. */
  getUsersAndTeams(): void {
    this.isLoading = true;
    this.errorGetUsersMember = false;

    const getMembers$ = !this.isFolder
      ? this.boardService.getUsersPermissionsDashboardPersonal(this.rithmId)
      : this.folderService.getUsersFolderPersonal(this.rithmId);
    const getTeams$ = !this.isFolder
      ? this.boardService.getTeamsDashboard(this.rithmId)
      : this.folderService.getTeamsFolderPersonal(this.rithmId);

    forkJoin([getMembers$, getTeams$])
      .pipe(first())
      .subscribe({
        next: ([permissionUsers, permissionTeams]) => {
          this.errorGetUsersMember = false;
          this.allTeams = permissionTeams;
          this.excludeUserLogin(permissionUsers);
          this.isUpdating = '';
          this.isLoading = false;
        },
        error: () => {
          this.errorGetUsersMember = true;
          this.isUpdating = '';
          this.isLoading = false;
          this.notify(
            'Could not obtain members and teams. Please try again in a little while.',
            true,
          );
        },
      });
  }

  /**
   * Parse users edited to update members.
   * @param type Type to get to update types: member | team.
   * @returns An array of MemberAddDashboard.
   */
  getUsersTeamsToUpdate(
    type: 'team' | 'member',
  ): DashboardOrFolderMemberPermission[] {
    const data: DashboardOrFolderMemberPermission[] = [];
    this.membersSpecific.addMembers.map((member) => {
      if (
        (this.form.controls[member.rithmId]?.value?.canView !==
          member.canView ||
          this.form.controls[member.rithmId]?.value?.isEditable !==
            member.isEditable) &&
        (type === 'team' ? 'name' : 'email') in member
      ) {
        data.push({
          rithmId: member.rithmId,
          isEditable: this.form.controls[member.rithmId]?.value?.isEditable,
          canView: this.form.controls[member.rithmId]?.value?.canView,
        });
      }
    });
    return data;
  }

  /**
   * Add permissions to members and teams for folders and dashboards.
   * @param users Users to edit.
   * @param teams Teams to edit.
   */
  addPermissions(
    users: DashboardOrFolderMemberPermission[] = [],
    teams: DashboardOrFolderMemberPermission[] = [],
  ): void {
    this.isLoading = true;
    !users.length &&
      !this.isUpdating &&
      (users = this.getUsersTeamsToUpdate('member'));
    !teams.length &&
      !this.isUpdating &&
      (teams = this.getUsersTeamsToUpdate('team'));

    const addPermission$ = !this.isFolder
      ? this.boardService.addDashboardMembers(this.rithmId, users, teams)
      : this.folderService.updatePermissionTeamMemberFolder(
          this.rithmId,
          users,
          teams,
        );

    addPermission$.pipe(first()).subscribe({
      next: () => {
        this.isLoading = false;
        this.isUpdating !== UpdateType.Update && this.getUsersAndTeams();
        this.notify('Permissions saved successfully');
        this.isUpdating = '';
      },
      error: () => {
        this.isLoading = false;
        this.isUpdating = '';
        this.notify(
          'The role could not be set correctly. Please try again in a little while.',
          true,
        );
      },
    });
  }

  /**
   * Exclude user login.
   * @param permissionUsers Permission users.
   */
  private excludeUserLogin(permissionUsers: MemberBoard[]): void {
    const userRithmId = this.userService.user().rithmId;
    const data: MemberBoard[] = [];
    permissionUsers.map((memberPotential) => {
      if (memberPotential.rithmId !== userRithmId) {
        data.push(memberPotential);
      }
    });

    this.allMembers = data;
    this.setSpecificMembers();
    this.addForms();
  }

  /**
   * Change search and Filter menu.
   * @param typeFilter Type filter changed.
   */
  changeSearchAndFilterMenu(typeFilter?: TypeFilter): void {
    typeFilter && (this.selectedFilter = typeFilter);
    // If we are in the Add Members tab.
    if (this.indexTab === 3) {
      this.isLoadingTabAddMember = true;
      setTimeout(() => {
        this.filterMembers();
        this.isLoadingTabAddMember = false;
      }, 500);
    } else {
      this.filterMembers();
    }
  }

  /**
   * Filter members and teams.
   */
  private filterMembers(): void {
    let filterMember: TypeTabs = 'teamMember';
    this.indexTab === 1 && (filterMember = 'member');
    this.indexTab === 2 && (filterMember = 'team');
    this.indexTab === 3 && (filterMember = 'addMembers');
    this.membersFiltered[filterMember] = this.membersSpecific[
      filterMember
    ].filter(
      (member) =>
        this.getValidateSearch(member) && this.getValidateFilter(member),
    );
  }

  /** Set specific members and teams to tabs. */
  setSpecificMembers(): void {
    this.membersSpecific = {
      addMembers: [...this.allMembers, ...this.allTeams].filter(
        ({ canView }) => !canView,
      ),
      team: this.allTeams.filter(({ canView }) => canView),
      member: this.allMembers.filter(({ canView }) => canView),
      teamMember: [...this.allMembers, ...this.allTeams].filter(
        ({ canView }) => canView,
      ),
    };
    this.membersFiltered = JSON.parse(JSON.stringify(this.membersSpecific));
    this.clearFilters();
  }

  /**
   * Update specific member or team.
   * @param member Member | team to delete.
   * @param typeUpdateMember Type of update.
   */
  updateMember(
    member: MemberBoard | TeamBoard,
    typeUpdateMember: UpdateType,
  ): void {
    this.isUpdating = typeUpdateMember;
    const permission: DashboardOrFolderMemberPermission[] = [
      {
        rithmId: member.rithmId,
        canView: typeUpdateMember === UpdateType.Update,
        isEditable:
          typeUpdateMember === UpdateType.Remove
            ? false
            : this.form.controls[member.rithmId].value.isEditable,
      },
    ];
    const isTeam = 'name' in member;
    this.addPermissions(!isTeam ? permission : [], isTeam ? permission : []);
    // If it is updated and different from the Add Members tab.
    if (typeUpdateMember === UpdateType.Update && this.indexTab !== 3) {
      const memberOrTeam =
        this.allMembers.find((item) => item.rithmId === member.rithmId) ||
        this.allTeams.find((item) => item.rithmId === member.rithmId);
      if (memberOrTeam) {
        // We update this member or team.
        const [firstPermission] = permission;
        memberOrTeam.canView = firstPermission.canView;
        memberOrTeam.isEditable = firstPermission.isEditable;
      }
      this.setSpecificMembers();
    }
  }

  /**
   * Initiate a confirmation popup to remove member.
   * @param member Member | team to delete.
   * @param typeUpdateMember Type of update.
   */
  async confirmActionDelete(
    member: MemberBoard | TeamBoard,
    typeUpdateMember: UpdateType,
  ): Promise<void> {
    const isTeam = 'name' in member;
    const response =
      typeUpdateMember === UpdateType.Remove
        ? await this.popupService.confirm({
            title:
              'Remove "' +
              (isTeam
                ? member.name
                : `${member.firstName} ${member.lastName}`) +
              '"?',
            message: 'This cannot be undone!',
            okButtonText: 'Yes',
            cancelButtonText: 'No',
            important: true,
          })
        : true;

    response && this.updateMember(member, typeUpdateMember);
  }

  /**
   * Notify and show pop-up for actions the permission the dashboard or folder.
   * @param message Message for show in popup.
   * @param isError If the message if error.
   */
  private notify(message: string, isError = false): void {
    this.popupService.notify(message, isError);
  }
}
