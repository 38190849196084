<ul class="w-full">
  @for (navItem of navItems; track navItem.name; let index = $index) {
    <li
      [routerLink]="navItem.link"
      [ngClass]="{ active: navItem.isActive }"
      class="w-full cursor-pointer list-none rounded-lg mt-2 p-2 option-background"
      appRightClickMenu
      [link]="'/' + navItem.link"
      [enableRightClick]="!isArchitect"
      (click)="sidenavDrawerService.closeSidenav()"
    >
      <a
        class="text-white no-underline hover:no-underline text-base font-medium flex items-center gap-2"
        [id]="'menu-button' + index"
        [attr.data-testid]="'menu-button' + index"
        ><i class="fa-light text-gray-400" [ngClass]="navItem.icon"></i>
        <span>{{ navItem.name }}</span></a
      >
    </li>
  }
</ul>
