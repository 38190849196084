import { Injectable } from '@angular/core';
import { BrowserNotification } from 'src/models';

/**
 * Service will handle the browser notification.
 */
@Injectable({
  providedIn: 'root',
})
export class BrowserNotificationService {
  /**
   *Main function to show the Browser notification.
   * @param browserNotification Display notification Object.
   */
  async showNotification(
    browserNotification: BrowserNotification,
  ): Promise<void> {
    let granted;
    if (Notification.permission !== 'denied') {
      const permission = await Notification.requestPermission();
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      granted = permission === 'granted' ? true : false;
    }
    // create a new notification
    const notification = new Notification(browserNotification.title, {
      body: browserNotification.bodyContent,
      icon: browserNotification.imgURL,
    });
    // close the notification after 10 seconds
    setTimeout(() => {
      notification.close();
    }, 5 * 1000);

    // navigate to a URL when clicked
    notification.addEventListener('click', () => {
      window.open(browserNotification.gotoURL, '_blank');
    });
  }
}
