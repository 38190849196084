<section class="notification-section">
  <p class="font-semibold truncate text-primary-500 text-xl">Notifications</p>
  <section class="notifications-list scrollbar">
    @if (!alertLoading && !alertError) {
      @for (notification of alertList.slice().reverse(); track $index) {
        <section class="card-notification">
          <app-notification-card
            [notification]="notification"
            [time]="notification.createdDateUTC"
            [fromMenu]="true"
            class="w-full"
          />
        </section>
      }
      @if (!alertList.length) {
        <ng-container [ngTemplateOutlet]="noNotification" />
      }
    }

    @if (alertError) {
      <div class="flex justify-center pt-2">
        <p
          class="text-error-A400 text-sm"
          id="error-get-alert-list"
          data-testid="error-get-alert-list"
        >
          Error: Unable retrieve notifications.
        </p>
      </div>
    }

    @if (alertLoading) {
      <div class="flex items-center justify-center">
        <app-loading-indicator [inlineText]="'Loading Notification...'" />
      </div>
    }

    <!-- No notification.-->
    <ng-template #noNotification>
      <div class="empty-state-notification">
        <img
          class="mb-4"
          ngSrc="assets/notification/empty-state.svg"
          width="180"
          height="160"
          alt="No notifications"
        />
        <p class="text-primary-400 text-2xl font-semibold py-2">
          No notifications
        </p>
        <p class="text-primary-300">Please check back later.</p>
      </div>
    </ng-template>
  </section>
</section>
