/** Contains the constants signalR. */

/** The ASP.NET Core SignalR service route. */
export const ALERT_HUB_PATH = '/alertservice/alerthub';

/** One Minute to milliseconds. */
export const MINUTE_TO_MILISEC = 60000;

/** Automatic reconnections for signalR given in millisecond. */
export const AUTO_RECONNECTIONS = [
  0,
  3000,
  5000,
  10000,
  15000,
  30000,
  MINUTE_TO_MILISEC,
  MINUTE_TO_MILISEC * 2.5,
  MINUTE_TO_MILISEC * 5,
  MINUTE_TO_MILISEC * 10,
  MINUTE_TO_MILISEC * 15,
  MINUTE_TO_MILISEC * 30,
  MINUTE_TO_MILISEC * 60,
];

/** Number of attempts limit to start connection. */
export const ATTEMPTS_LIMIT_CONNECTION = 5;
