import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component } from '@angular/core';
import { Alert } from 'src/models';
import { NotificationCardComponent } from '../notification-card/notification-card.component';
/**
 * Container for the toast notifications.
 */
@Component({
  selector: 'app-notification-toasts-container',
  templateUrl: './notification-toasts-container.component.html',
  styleUrls: ['./notification-toasts-container.component.scss'],
  standalone: true,
  imports: [CommonModule, NotificationCardComponent, NgOptimizedImage],
})
export class NotificationToastsContainerComponent {
  /** Array of Notifications. */
  notifications: Alert[] = [];

  /**
   * Remove a notification from the array.
   * @param index The notification to remove.
   */
  removeNotification(index: number): void {
    setTimeout(() => {
      this.notifications.splice(index, 1);
    }, 1000);
  }
}
