import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, fromEvent, map, merge, takeUntil } from 'rxjs';

/**
 * Service for all network related behaviors.
 */
@Injectable({
  providedIn: 'root',
})
export class NetworkService implements OnDestroy {
  /** Destroyed. */
  private destroyed$ = new Subject<void>();

  /** The current network status 'True online, false offline'. */
  networkStatus$: Observable<boolean>;

  constructor() {
    this.networkStatus$ = merge(
      fromEvent(window, 'online'),
      fromEvent(window, 'offline'),
    ).pipe(
      map(() => navigator.onLine),
      takeUntil(this.destroyed$),
    );
  }

  /**
   * Destroy life cycle.
   */
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
