import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { first, Subject, takeUntil } from 'rxjs';
import { AlertService } from 'src/app/core/alert.service';
import { PopupService } from 'src/app/core/popup.service';
import { SignalRService } from 'src/app/core/signalR-service';
import { LastUpdatedPipe } from 'src/helpers/pipes/last-updated/last-updated.pipe';
import { Alert, AlertType } from 'src/models';
import { NotificationCardComponent } from '../notification-card/notification-card.component';
import { LoadingIndicatorComponent } from 'src/app/shared/loading-indicator/loading-indicator.component';

/** Notification container component. */
@Component({
  selector: 'app-notification-menu-container',
  templateUrl: './notification-menu-container.component.html',
  styleUrls: ['./notification-menu-container.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    NotificationCardComponent,
    LoadingIndicatorComponent,
    LastUpdatedPipe,
    NgOptimizedImage,
  ],
  providers: [LastUpdatedPipe],
})
export class NotificationMenuContainerComponent implements OnInit, OnDestroy {
  /** Observable for when the component is destroyed. */
  private destroyed$ = new Subject<void>();

  /** List of all the alerts. */
  alertList: Alert[] = [];

  /** Notification alert mark read alertId. */
  alertIds: string[] = [];

  /** Included read alert. */
  includeRead = true;

  /** Catch error if API fails. */
  alertError = false;

  /** Indicates to display loading when API request underway. */
  alertLoading = false;

  constructor(
    private alertService: AlertService,
    private popupService: PopupService,
    private signalRService: SignalRService,
    public timeConversion: LastUpdatedPipe,
  ) {}

  /** Sort notifications by read value. */
  ngOnInit(): void {
    this.getAlerts();
    this.subscribeNewNotification();
    this.subscribePassingNotification$();
  }

  /** Subscribe to signalR notification event. */
  private subscribeNewNotification(): void {
    this.signalRService.receivedAlert$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((alert) => {
        if (alert.type === AlertType.Silent) {
          this.alertList = [alert].concat(this.alertList);
        }
      });
  }

  /** Subscribe to alert notification from push menu. */
  private subscribePassingNotification$(): void {
    this.alertService.passingAlert$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((alert) => {
        if (alert) {
          this.alertList = alert.concat(this.alertList);
        }
      });
  }

  /**
   * Gets a list of alerts.
   */
  private getAlerts(): void {
    this.alertLoading = true;
    this.alertService
      .getAlerts(this.includeRead)
      .pipe(first())
      .subscribe({
        next: (data) => {
          const alertData = data.filter(
            (item) =>
              (item.type === AlertType.Silent || AlertType.Push) &&
              !(
                (this.timeConversion
                  .transform(item.createdDateUTC)
                  .match(/(day)/g)?.length || 0) > 0 &&
                parseInt(
                  this.timeConversion
                    .transform(item.createdDateUTC)
                    .split(' ')[0],
                ) > 30
              ),
          );
          if (alertData) {
            this.alertList = alertData;
            this.markAlertsAsRead();
          }
          this.alertError = false;
          this.alertLoading = false;
        },
        error: () => {
          this.alertError = true;
          this.alertLoading = false;
        },
      });
  }

  /**
   * Update alerts mark read list.
   */
  private markAlertsAsRead(): void {
    this.alertIds = this.alertList
      .filter((alert) => alert.hasRead === false)
      .map((a) => a.rithmId);
    this.alertService
      .markAlertsAsRead(this.alertIds)
      .pipe(first())
      .subscribe({
        next: (ids) => {
          if (ids) {
            this.alertIds.map((alertId) => {
              const alertIndex = this.alertList.findIndex(
                (alert) => alert.rithmId === alertId,
              );
              this.alertList[alertIndex].hasRead = true;
            });
          }
        },
        error: () => {
          this.popupService.notify(
            "Something went wrong on our end and we're looking into it. Please try again in a little while.",
            true,
          );
        },
      });
  }

  /**
   * Completes all subscriptions.
   */
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
