import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  OnDestroy,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  FormGroup,
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Subject, takeUntil } from 'rxjs';
import { AvatarFileComponent } from 'src/app/shared/avatar-file/avatar-file.component';
import { UserAvatarComponent } from 'src/app/shared/user-avatar/user-avatar.component';
import { TermsGeneric } from 'src/helpers';
import { MemberBoard, TeamBoard, UpdateType } from 'src/models';

interface MemberUpdate {
  /** Member. */
  member: MemberBoard | TeamBoard;
  /** Type of update. */
  updateType: UpdateType;
}

/** List members. */
@Component({
  selector: 'app-member-board-list-modal[member][ownUserRithmId]',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatCheckboxModule,
    MatMenuModule,
    MatButtonModule,
    MatTooltipModule,
    AvatarFileComponent,
    UserAvatarComponent,
  ],
  templateUrl: './member-board-list-modal.component.html',
  styleUrls: ['./member-board-list-modal.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MemberBoardListModalComponent),
      multi: true,
    },
  ],
})
export class MemberBoardListModalComponent
  implements OnInit, ControlValueAccessor, OnDestroy
{
  /** Member board. */
  @Input() member!: MemberBoard | TeamBoard;

  /** Id of the user who owns the board. */
  @Input() ownUserRithmId = '';

  /** Index member. */
  @Input() index!: number;

  /** If is template to add member. */
  @Input() isAddMember = false;

  /** Is loading. */
  @Input() isLoading = false;

  /** Is folder. */
  @Input() isFolder = false;

  /** Subject for whether the component was destroyed/unsubscribe. */
  private destroyed$ = new Subject<void>();

  private _canViewAll = false;

  /** Status canViewAll. */
  @Input() set canViewAll(status: boolean) {
    this._canViewAll = status;
    if (this.form) {
      if (status) {
        this.isCanView = true;
      }
      if (!this.isCanView) {
        this.form.patchValue({
          canView: status,
          isEditable: false,
        });
      }
    }
  }

  /**
   * Get canViewAll.
   * @returns A boolean.
   */
  get canViewAll(): boolean {
    return this._canViewAll;
  }

  /** Deselect all members. */
  @Output() deselectCanViewAll = new EventEmitter<void>();

  /** Remove member. */
  @Output() updateMember = new EventEmitter<MemberUpdate>();

  /**
   * Get status canView.
   * @returns Status canView.
   */
  get canView(): boolean {
    return this.form.controls['canView'].value;
  }

  /**
   * Get isEditable.
   * @returns Status isEditable.
   */
  get isEditable(): boolean {
    return this.form.controls['isEditable'].value;
  }

  /**
   * If the member is team.
   * @returns Boolean true when is team.
   */
  get isTeam(): boolean {
    return 'name' in this.member;
  }

  /**
   * If the member is team.
   * @returns Boolean true when is team.
   */
  get getTeam(): TeamBoard {
    return this.member as TeamBoard;
  }

  /**
   * If the member is team.
   * @returns Boolean true when is team.
   */
  get getMember(): MemberBoard {
    return this.member as MemberBoard;
  }

  /** Form user. */
  form!: FormGroup;

  /** Member can view. */
  isCanView = false;

  /** Enum update type. */
  enumUpdateType = UpdateType;

  /** System-wide generic terms. */
  termsGeneric = TermsGeneric;

  constructor(private fb: FormBuilder) {}

  /** Init method. */
  ngOnInit(): void {
    this.form = this.fb.group({
      canView: this.fb.control(this.member.canView),
      isEditable: this.fb.control(this.member.isEditable),
    });
  }

  /**
   * The `onTouched` function.
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched: () => void = () => {};

  /**
   * Set value in user.
   * @param value Set value.
   */
  // eslint-disable-next-line
  writeValue(value: any): void {
    if (value) {
      this.form.setValue(value);
    }
  }

  /**
   * Registers a function with the `onChange` event.
   * @param fn The function to register.
   */
  // eslint-disable-next-line
  registerOnChange(fn: any): void {
    this.form.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(fn);
  }

  /**
   * Registers a function with the `onTouched` event.
   * @param fn The function to register.
   */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /**
   * Status isEditable.
   * @param role The role of member.
   */
  onChangeIsEditable(role: string): void {
    if (this.canView) {
      this.form.patchValue({
        isEditable: role === 'admin',
      });
      !this.isAddMember && this.updateMemberEmit(UpdateType.Update);
    }
  }

  /**
   * Change canView.
   */
  onChange(): void {
    this.isCanView = this.canView;
    if (!this.canView) {
      this.form.patchValue({
        isEditable: false,
      });
      if (this.canViewAll) {
        this.deselectCanViewAll.emit();
      }
    }
  }

  /**
   * Update member.
   * @param updateType Type to update member.
   */
  updateMemberEmit(updateType: UpdateType): void {
    this.updateMember.emit({
      member: this.member,
      updateType: updateType,
    });
  }

  /**
   * Completes all subscriptions.
   */
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
